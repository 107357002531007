<template>
    <!-- 导航 -->
    <page-head style="z-index: 30000000;position: absolute" title="智慧球场"/>
    <div class="videoBg">
        <div class="videoBox"
             id="videoBox"
             v-show="videoFlag"></div>

        <div class="loading">
            <img src="@i/gif/loading.gif">
        </div>

        <div class="changeVideo" v-if="videoFlag">
            <p class="text">摄像头</p>
            <div v-for="(item,key,index) in smartPitchCamera[route.query.pitch].channels" :key="item.channel">
                <p class="videoItem"
                   :class="(key === videoItem.channel) ? 'check' : '' "
                   @click="changeVideoFn({channel: key})">{{ index + 1 }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {useRouter, useRoute} from "vue-router";
import {getCurrentInstance, onBeforeUnmount, onMounted, reactive, toRefs} from "vue";
import {useStore} from "vuex";

export default {
    name: "smartPitchDetail",

    setup() {
        const router = useRouter();
        const route = useRoute();
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            smartPitchList: [],

            smartPitchCamera: {},
            playUrl: '',

            myVideo: null,
            videoFlag: false,

            videoItem: {},
        })

        onMounted(() => {
            state.myVideo ? state.myVideo.dispose() : '';
        })

        const createVideo = () => {
            // console.log('创建标签')
            let box = document.getElementById('videoBox');
            // 获取 div 标签下的所有子节点
            var pObjs = box.childNodes;
            for (var i = pObjs.length - 1; i >= 0; i--) { // 一定要倒序，正序是删不干净的，可自行尝试
                box.removeChild(pObjs[i]);
            }


            let video = document.createElement('video');
            video.id = 'smartPitch';
            video.style.width = '100%';

            box.appendChild(video);
        }

        const infoVideo = (pitch, video) => {
            console.log('点击播放');
            console.log(state.smartPitchCamera[pitch])
            console.log(video);

            state.myVideo ? state.myVideo.dispose() : '';

            let videoItem = state.smartPitchCamera[pitch].channels[video.channel];

            state.playUrl = `http://${videoItem.properties.ip}:${videoItem.properties.server_port}/stream/${pitch}/channel/${video.channel}/hls/live/index.m3u8`

            // 创建video标签
            createVideo(state.playUrl);

            console.log(state.playUrl);

            let timer = setTimeout(() => {

                state.myVideo = proxy.$video(document.getElementById('smartPitch'), {

                    autoplay: true,

                    controls: true,

                    bigPlayButton: false,

                    textTrackDisplay: false,

                    posterImage: false,

                    errorDisplay: false,

                }, function () {
                    clearTimeout(timer)

                    this.on('playing', function () {
                        if (!state.videoFlag) {
                            state.videoFlag = true;
                        }
                    })

                }).src({
                    src: state.playUrl,
                    type: 'application/x-mpegURL'
                })
            })
        }


        onMounted(() => {
            proxy.$server.getSmartPitchDevices()
                .then(res => {
                    state.smartPitchList = res.data;
                })

            proxy.$server.getSmartPitchStreams()
                .then(res => {
                    state.smartPitchCamera = res.data;
                    state.videoItem = JSON.parse(route.query.video)
                    infoVideo(route.query.pitch, state.videoItem)

                })
        })

        const changeVideoFn = (video) => {
            state.videoFlag = false;
            state.videoItem = video;
            infoVideo(route.query.pitch, video)
        }

        onBeforeUnmount(() => {
            state.myVideo ? state.myVideo.dispose() : ''
        })

        return {
            ...toRefs(state),
            route,

            changeVideoFn,
        }

    },
}
</script>

<style scoped lang="scss">

.videoBg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1000;
}

.videoBox {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    #smartPitch {
        width: 100%;
    }
}

.loading {
    color: rgba(255, 255, 255, .4);
    text-align: center;
    margin-top: 15%;
}

::v-deep .vjs-loading-spinner, ::v-deep .vjs-control-bar, ::v-deep .vjs-modal-dialog {
    display: none !important;
}

.changeVideo {
    position: fixed;
    top: 30%;
    right: 0;
    z-index: 3000000;
    background: rgba(19, 20, 27, .8);
    border-radius: 15px 0 0 15px;
    padding-top: 1vw;
    padding-bottom: .5vw;
    text-align: center;
    .text {
        font-size: 26px;
        margin-bottom: 1vw;
        color: rgba(255, 255, 255, .9);
    }

    .videoItem {

        font-family: akrobatBold;
        background: rgba(19, 20, 27, 0.5);
        color: rgba(255, 255, 255, .4);
        border: 2px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        width: 3vw;
        height: 3vw;
        line-height: 3vw;
        margin-left: 1.5vw;
        margin-right: 1vw;
        font-size: 28px;
        margin-bottom: 1vw;

        &.check {
            background: rgba(54, 99, 225, 0.5);
            border: 2px solid #3663E1;
            color: #FFFFFF;
        }
    }
}
</style>